/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

// We use 'em' unit for dimensions in order to respect exports ratio

.attributions {
  position: absolute;
  bottom: 0;
  right: 0;

  z-index: $zIndex-attributions;

  .content {
    padding: 0.6em 1em;
    background: rgba(white, 0.6);
    border-radius: 0.4em;
  }

  .attribution {
    margin-bottom: 0.2em;
    text-align: right;
  }

  .softwareBrand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    img {
      width: 1.5em;
      margin-left: 0.5em;
    }
  }
}
