/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

.attributions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  font-size: 0.8rem;
  color: $gray-700;

  background-color: rgba(255, 255, 255, 0.8);
  border-radius: $border-radius;

  user-select: none;

  a {
    color: $gray-700 !important;
    font-weight: normal !important;

    &:hover {
      text-decoration: underline;
    }
  }

  // On hover we increase contrast
  &:hover {
    color: $gray-900;
    background-color: rgba(255, 255, 255, 1);

    a {
      color: $gray-900 !important;
    }
  }
}

.attribution {
  margin: 0.2rem 0.4rem;
}
