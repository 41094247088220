/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

// Here we must use em as unit, for exports

.scale {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  z-index: $zIndex-scale;

  background: rgba(white, 0.9);
  padding: 0.5em 1em;

  color: $secondary;
  font-size: 0.9em;
  border-radius: 0.4em;
  border: solid 0.1rem $control-color;

  cursor: default;
  user-select: none;
}

.line {
  border-bottom: solid 0.2em $control-color;
  margin: 0 1em;

  transition: all 0.25s;
}
