/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

.table {
  font-size: 1em;

  td {
    vertical-align: center;
    min-width: 3em;
  }

  // When no border and edition enabled we display a slight border to help user
  &.border0 {
    td {
      border: dashed 1px $gray-400;
    }
  }

  // When no border and edition disabled we display a transparent border to prevent shifts
  &.border0.readOnly {
    td {
      border: solid 1px transparent;
    }
  }

  &.border1 {
    td {
      // We should not use subdivisions of px here (rem/em)
      border: solid 1px gray;
    }
  }

  &.border2 {
    td {
      border: solid 3px gray;
    }
  }

  &.border3 {
    td {
      border: solid 6px gray;
    }
  }
}
