/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'variables';
@import 'scrollbars';

// html, body and #root are reset earlier in index.html

html,
body {
  font-family: 'AbcCantarell', sans-serif;
  margin: 0;
  background-color: white;

  font-size: 14px;

  // We should use a higher value for small devices, but it would require more work
  @include media-breakpoint-down(md) {
    font-size: 2.2vmin;
  }
}

// We must set it in global context
@include defaultScrollbars($scrollbar_width_md);

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

button {
  color: $input-color;
}

.dropdown-item {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.abc-selection-box {
  border: 0.15rem dashed #828282;
}

.abc-toast {
  font-size: 1.1em;
  min-height: 4em;

  border-radius: $border-radius;
}

.modal-title {
  font-size: 1.2rem;
}

.abc-text-box-button {
  background: white;

  &:hover {
    background: $primary;
  }
}
