/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/mixins';
@import 'src/styles/variables';

.enableForm {
  width: 70vmin;

  @include media-breakpoint-down(xl) {
    width: 100%;
  }
}

.authButton {
  width: 15rem;
  margin: 0 1rem;
}

.statusText {
  display: flex;
  align-items: center;

  margin: 2rem 0;

  font-size: 1.2rem;

  button {
    font-size: 1.2rem;
  }
}

.icon {
  margin-right: 0.6rem;

  &.enabled {
    color: $primary;
  }

  &.disabled {
    color: $gray-600;
  }
}
