/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

.listItem {
  display: flex;
  align-items: center;

  margin: 0.8rem;
  padding: 0.8rem;
  border: solid 0.1rem $gray-400;
  border-radius: 0.5rem;

  cursor: pointer;
  user-select: none;

  .title {
    flex-grow: 1;
    font-size: 1.2rem;
  }

  .btn {
    border: 0;
    background: inherit;
  }

  &:hover {
    background: rgba($gray-400, 0.4);
  }

  &.active {
    color: white;
    background: $primary;

    :global(.abc-icon) {
      color: white;
    }
  }
}
