/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';
@import 'src/styles/mixins';
@import '~@abc-map/user-documentation/src/styles/shared-content-style';

.documentation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100vw;
  height: 100%;
  max-height: 100%;

  padding: 1rem;

  overflow-x: auto;
  overflow-y: auto;
}

.pageNotLoaded {
  font-size: 1.5rem;
  margin: 5rem 0;
}

.topButtons {
  position: fixed;
  top: 4rem;
  right: 0.5rem;

  background: white;

  display: flex;
  flex-wrap: wrap;
}

.contentWrapper {
  padding: 2rem 25vw;
  @include media-breakpoint-down(xl) {
    padding: 1rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include documentationContentStyle();
}
