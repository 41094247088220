/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

.container {
  position: relative;

  .menu {
    position: absolute;
    top: 1em;
    left: 1em;

    width: 2em;
    height: 2.5em;
    background-color: white;
    border-radius: $border-radius;

    opacity: 0;
    transition: opacity 400ms;
  }

  &:hover {
    .menu {
      opacity: 1;
    }
  }

  .img {
    border: solid 1px $gray-400;
    box-shadow: 0 0.5em 0.8em rgba($gray-600, 0.1);
    cursor: move;

    &.readonly {
      cursor: pointer;
    }
  }

  &.size1 {
    .img {
      width: 30%;
      max-width: 30%;
    }
  }

  &.size2 {
    .img {
      width: 70%;
      max-width: 70%;
    }
  }

  &.size3 {
    .img {
      width: 100%;
      max-width: 100%;
    }
  }
}

.fullscreenModal {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.5);

  z-index: $zIndex-textEditor-fullscreenPreview;
}

.largePreview {
  height: 75vh;
  max-height: 75vh;
  max-width: 80vw;

  cursor: pointer;
}
