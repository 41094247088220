/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/scrollbars';

.topBar {
  height: $top-bar-height;
  min-height: $top-bar-height;
  max-height: $top-bar-height;

  z-index: $zIndex-topBar;

  width: 100vw;
  max-width: 100vw;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-bottom: solid 0.1rem $gray-400;
  background: white;
  padding: 0;

  box-shadow: $box-shadow;
}

.brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-width: 11rem;
  width: 11rem;
  height: 4vh;
  max-height: 100%;

  border-radius: $border-radius;
  border: none;

  font-size: 1.3rem;
  background: white;

  padding: 0.5rem;
  margin-left: 2rem;
  margin-right: 2rem;

  span {
    white-space: nowrap;
  }

  img {
    height: 2.5vh;
    margin-right: 1rem;
  }
}

.link {
  // Warning: this size is used to compute menu display in top bar
  min-width: 12rem;
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0);

  transition: opacity ease-in-out 80ms;

  &.visible {
    background: rgba(0, 0, 0, 0.25);
    left: 0;
  }
}
