/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'variables';

.control-block {
  padding: 0.8rem;
  margin: 0;
  border-bottom: solid $gray-300 0.1rem;

  .control-item {
    margin: 0 0 0.5rem 0;
    padding: 0;

    .btn-link {
      padding: 0;

      .abc-icon {
        color: $gray-500;
      }

      &:hover {
        text-decoration: none;

        .abc-icon {
          color: $gray-700;
        }
      }
    }
  }
}
