/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

.mapContainer {
  position: relative;
  border: solid $gray-400 0.1rem;
  box-shadow: $box-shadow;

  // Needed for frame shadows
  overflow: hidden;
}

.navigation {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1.5rem;
}

.viewList {
  position: absolute;
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  width: 25rem;
}

.attributions {
  position: absolute;
  bottom: 0.4rem;
  right: 0.4rem;
}
