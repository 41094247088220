/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';
@import 'src/styles/mixins';

.message {
  font-weight: bolder;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.output {
  margin-top: 1rem;
  pre {
    padding: 1rem;
    margin-top: 1rem;
    border: solid 0.1rem #ced4da;
  }
}

.editorContainer {
  border: 0.1rem solid $gray-200;
}
