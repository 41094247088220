/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

.openButton {
  padding: 0;
  margin: 1rem 0 0 0;
}

.modalBody {
  display: flex;
  flex-direction: column;

  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;

  h1 {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 1.3rem;
    margin-bottom: 0.7rem;
  }

  .section {
    margin-bottom: 2rem;
  }
}

.licenceBlock {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.1rem;
}
