/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'variables';

$scrollbar_bg: $gray-200;
$scrollbar_fg: $gray-500;
$scrollbar_width_lg: 0.8rem;
$scrollbar_width_md: 0.5rem;
$scrollbar_width_sm: 0.3rem;

@mixin defaultScrollbars($scrollbar_width: $scrollbar_width_md) {
  ::-webkit-scrollbar {
    width: $scrollbar_width;
    height: $scrollbar_width;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: $scrollbar_fg;
    border: 0 none #ffffff;
    border-radius: 2.5rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $scrollbar_fg;
  }

  ::-webkit-scrollbar-thumb:active {
    background: $scrollbar_fg;
  }

  ::-webkit-scrollbar-track {
    background: $scrollbar_bg;
    border: 0 none #ffffff;
    border-radius: 2.5rem;
  }

  ::-webkit-scrollbar-track:hover {
    background: $scrollbar_bg;
  }

  ::-webkit-scrollbar-track:active {
    background: $scrollbar_bg;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}
