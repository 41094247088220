/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

.icon {
  color: $gray-600;
}

// PRIMARY

:global(.btn-primary) {
  .icon {
    color: white;
  }

  &:hover {
    .icon {
      color: white;
    }
  }

  &[disabled]:hover {
    .icon {
      color: white;
    }
  }
}

// OUTLINE PRIMARY

:global(.btn-outline-primary) {
  .icon {
    color: $primary;
  }

  &:hover {
    .icon {
      color: white;
    }
  }
}

// SECONDARY

:global(.btn-secondary) {
  .icon {
    color: white;
  }

  &:hover {
    .icon {
      color: white;
    }
  }

  &[disabled]:hover {
    .icon {
      color: $gray-600;
    }
  }
}

// OUTLINE SECONDARY

:global(.btn-outline-secondary) {
  .icon {
    color: $gray-600;
  }

  &:hover {
    .icon {
      color: white;
    }
  }

  &[disabled]:hover {
    .icon {
      color: $gray-600;
    }
  }
}
