/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

.menuPanel {
  position: fixed;
  top: $top-bar-height + 1.5rem;
  bottom: 1.5rem;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  background: transparent;

  z-index: $zIndex-sideMenu-panel;

  &.right {
    right: 1.5rem;
    flex-direction: row;
  }

  &.left {
    left: 1.5rem;
    flex-direction: row-reverse;
  }

  .menuContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    width: 27rem;
    min-width: 24rem;
    height: 100%;
    max-height: 100%;

    border: solid 0.1rem $gray-300;
    border-radius: $border-radius;

    background: white;

    overflow-x: auto;
    overflow-y: auto;
    scrollbar-gutter: stable;

    box-shadow: $box-shadow;
  }

  .closeButton {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0.2rem 0.5rem;
    padding: 0.5rem;

    border: none;
    border-radius: $border-radius;
    background-color: $control-color;

    box-shadow: $box-shadow;

    &:hover {
      background-color: $control-color;

      .icon {
        opacity: 0.9;
      }
    }
  }
}
