/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';
@import 'src/styles/mixins';

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 3vmin;
  background: white;

  font-size: 1.5rem !important;

  button {
    font-size: 1.5rem !important;
    padding: 1vmin 2vmin;
    margin-right: 1rem;

    width: 20rem;
  }

  img {
    width: 20vmin;
    margin-bottom: 7vmin;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 30vh;
}

.title {
  font-size: 3rem;
  margin-bottom: 2.5rem;
}

.explanation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-bottom: 1rem;
    text-align: center;

    @include media-breakpoint-down(xl) {
      width: 75vw;
    }
  }

  li {
    text-align: left;
  }
}
