/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: $zIndex-floatingTextFrame;

  // Can be overrided by style
  background: white;

  &.withBorders {
    border: solid 0.1em $gray-400;
    border-radius: 0.4em;
  }

  &.withShadows {
    box-shadow: $box-shadow;
  }

  .controls {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    .controls {
      opacity: 1;
    }
  }
}

.fullscreenEditor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.5);

  z-index: $zIndex-floatingTextFrame-fullscreenEditor;

  .editorContainer {
    position: relative;
    width: 70vw;
    height: 85vh;

    padding: 0.8rem;
    background: white;
    border: solid 1px $gray-400;
    border-radius: $border-radius;

    @include media-breakpoint-down(xl) {
      width: 95vw;
      height: 90vh;
    }
  }

  .closeButton {
    position: absolute;
    top: -3rem;
    right: -3rem;

    font-size: 2rem;

    background: none;
    border: none;

    @include media-breakpoint-down(xl) {
      top: -4rem;
      right: 0;
    }
  }
}
