/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';
@import 'src/styles/mixins';

.table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  border-collapse: separate;
  border-spacing: 0.4rem;

  flex-grow: 1;
  height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;

  margin-bottom: 1rem;
}

.label {
  width: 12rem;
  min-width: 12rem;
  max-width: 12rem;
}

.value {
  width: 35rem;
  @include media-breakpoint-down(md) {
    width: 25rem;
    max-width: 100%;
  }

  display: flex;
  align-items: center;
}

.buttonNew {
  margin-left: 0.4rem;

  :global(.abc-icon) {
    color: $primary;
  }
}
