/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 3vmin;
  background: white;

  .title {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  font-size: 1.5rem !important;
  div,
  button {
    font-size: 1.5rem !important;
  }

  :global(.btn) {
    padding: 1vmin 2vmin;
  }

  img {
    width: 20vmin;
    margin-bottom: 7vmin;
  }

  .explanation1 {
    text-align: center;
    margin: 0 3vmin 4vmin 3vmin;
  }

  .explanation2 {
    text-align: center;
    margin: 0 3vmin 3vmin 3vmin;

    font-weight: bold;
  }
}
