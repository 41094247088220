/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';
@import 'src/styles/mixins';

.artefact {
  margin-bottom: 0.7rem;
  margin-right: 1rem;

  // Warning: this value is used to compute the number of displayed artifacts
  min-height: 6rem;

  width: 28vw;
  max-width: 28vw;

  @include media-breakpoint-down(xl) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  cursor: pointer;
  box-shadow: $box-shadow;

  &.selected {
    background: $gray-200;
  }

  h1 {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 1.3rem;
  }
}

.description {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
