/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';
@import 'src/styles/mixins';

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  height: 100%;
  width: 100%;

  overflow-x: hidden;
  overflow-y: auto;

  h1 {
    text-align: center;
  }
}

.centerFrame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: solid 0.1rem $gray-300;
  border-radius: $border-radius;

  padding: 1.2rem;
  margin-bottom: 2rem;
  box-shadow: $box-shadow;

  width: 40%;
  @include media-breakpoint-down(xl) {
    width: 90%;
  }
}

.voteBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 6rem;
  height: 6rem;
  margin: 0.5rem;

  border: none !important;
}

.face {
  display: block;
  font-size: 1.7rem;
  margin-bottom: 0.4rem;
}

.feedbackArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;
  width: 40rem;

  @include media-breakpoint-down(xl) {
    width: 70%;
  }
}
