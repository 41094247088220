/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */
@import 'src/styles/variables';
@import 'src/styles/mixins';

.datastoreView {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;

  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;

  padding: 1rem;

  overflow-x: hidden;
  overflow-y: hidden;
}

.leftPart {
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  height: 100%;
  max-width: 100%;

  // A bit of margin for scrollbars
  margin-right: 1rem;
}

.rightPart {
  min-width: 35vw;
  width: 35vw;
  max-width: 35vw;

  height: 100%;
}

.artefactList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  align-content: flex-start;

  width: 100%;
  height: 100%;

  padding-bottom: 1rem;
  padding-right: 1rem;

  overflow-x: hidden;
  overflow-y: auto;
}
