/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

.dataSourceSelector {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.displayControl {
  margin-right: 1.8rem;
  cursor: pointer;

  input[type='radio'] {
    margin-right: 0.8rem;
  }
}

.selectVectorLayer {
  width: 30vw;
  max-width: 30vw;
}

.message {
  margin-left: 2rem;
}

.selectFile {
  width: 30vw;
  max-width: 30vw;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  border: solid 0.1rem $card-border-color;
  border-radius: $border-radius;
}
