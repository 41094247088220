/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(2.18vmin, 0);
  }
}

.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 7.3vmin;
  height: 7.3vmin;
}

.ldsEllipsis div {
  position: absolute;
  top: 3vmin;
  width: 1.2vmin;
  height: 1.2vmin;
  border-radius: 50%;
  background: rgba(0, 119, 182, 0.48);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ldsEllipsis div:nth-child(1) {
  left: 0.72vmin;
  animation: lds-ellipsis1 0.4s infinite;
}

.ldsEllipsis div:nth-child(2) {
  left: 0.72vmin;
  animation: lds-ellipsis2 0.4s infinite;
}

.ldsEllipsis div:nth-child(3) {
  left: 3vmin;
  animation: lds-ellipsis2 0.4s infinite;
}

.ldsEllipsis div:nth-child(4) {
  left: 6vmin;
  animation: lds-ellipsis3 0.4s infinite;
}
