/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

.slider {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background-color: $gray-100;
  border-radius: $border-radius;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.button {
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: none;
  box-shadow: $box-shadow;
  border-radius: 50%;
  border: none;
  padding: 0;
  margin: 0;

  &.left {
    top: 50%;
    transform: translate(0, -50%);
    left: 1rem;
  }

  &.right {
    top: 50%;
    transform: translate(0, -50%);
    right: 1rem;
  }

  &:disabled {
    :global(.abc-icon) {
      display: none;
    }
  }
}

.attributions {
  position: absolute;
  bottom: 0.1rem;
  left: 0.1rem;
  padding: 0.4rem;
  font-size: 0.9rem;

  background-color: #ffffffaa;
  border-radius: $border-radius;
}
