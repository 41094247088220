/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

.list {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: rgba(255, 255, 255, 0.95);
  border: solid 0.1rem $gray-400;
  border-radius: $border-radius;

  z-index: $zIndex-sharedMap-menu;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0.5rem;
  padding: 0.5rem;

  border: none;
  border-radius: $border-radius;
  background-color: $control-color;
  box-shadow: $box-shadow;

  color: white;

  .icon {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: $secondary;

    .icon {
      opacity: 0.9;
    }
  }
}
