/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';
@import 'src/styles/mixins';

.details {
  display: flex;
  flex-direction: column;

  min-height: 100%;
  height: 100%;

  overflow: auto;

  padding: 2rem;

  border: solid 1px $gray-400;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  background-color: white;

  @include media-breakpoint-down(xl) {
    display: none;
  }

  &.mobileVisible {
    @include media-breakpoint-down(xl) {
      display: flex;
      z-index: $zIndex-datastore-detailsPartMobile;

      position: fixed;
      top: 0;
      left: 0;

      width: 100vw;
      max-width: 100vw;
      height: 100vh;
      max-height: 100vh;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1rem;
}

.noArtefactMessage {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 1.3rem;
}

.actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  a,
  button {
    margin-right: 1.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.1rem;
  }
}

.mobileCloseButton {
  display: none;

  @include media-breakpoint-down(xl) {
    display: block;
    border: none;
    background: none;
  }
}

.description {
  cursor: pointer;
  max-height: 4.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.descriptionModalContent {
  word-wrap: break-word;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 1.1rem;
}

.aboutLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.3rem;

  a {
    max-width: 60%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .label {
    width: 25%;
    margin-right: 0.5rem;
  }
}
