/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';

.container {
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: $zIndex-sideMenu-button;

  background: transparent;
  border-radius: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem;

  border: solid 0.1rem $control-color;
  border-radius: 100%;
  box-shadow: $box-shadow;
  background-color: rgba(white, 0.9);

  .icon {
    color: $control-color;
  }

  &:hover:not([disabled]) {
    background-color: $control-color;

    .icon {
      color: white;
    }
  }
}
