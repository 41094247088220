/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

@import 'src/styles/variables';
@import 'src/styles/mixins';

.topBarLink {
  display: flex;
  align-items: flex-start;

  border: none;
  background-color: inherit;

  white-space: nowrap;

  &.horizontal {
    padding: 0.9rem 0.5rem 0.5rem 0.5rem;
    justify-content: center;

    border-bottom: solid 0.35rem white;
    &.active {
      border-bottom: solid 0.35rem $primary;
    }

    height: 100%;
  }

  &.vertical {
    padding: 0.5rem;
    justify-content: flex-start;

    border-left: solid 0.45rem white;
    &.active {
      border-left: solid 0.45rem $primary;
    }
  }
}
